/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { graphql } from 'gatsby';
import { HomePage } from '@page-templates';

// eslint-disable-next-line react/prop-types
export default ({ location, data }) => (
  <HomePage
    location={location}
    htmlTitle="Yevhen Kryvonos"
    htmlDescription=""
    htmlKeywords={[]}
    title="Yevhen Kryvonos"
  />
);

export const pageQuery = graphql`
  query HomePageQueryRu {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`;
